.app {
  width: 100vw;
  height: 100vh;
  /* background-color: black; */
  color: rgb(110, 185, 55);
}

.header {
  width: 100vw;
  height: 10vh;
  background-color: Rgb(244, 244, 244);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header__logo {
  width: 80vw;
}

.header__title {
  width: 20vw;
  display: flex;
  /* cursor: pointer; */
}

.quotediv {
  width: 100vw;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.quotediv__imgdiv {
  margin-top: 90px;
}

.quotediv__text {
  font-size: 44px;
  font-family: Montserrat, sans-serif;
  /* color: rgb(110, 185, 55); */
  color: #3B3B3B;
}

.quotediv__slogan {
  width: 100vw;
  text-align: center;
}

.maindiv {
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.maindiv__card {
  width: 60vw;
  height: 50vh;
  border-radius: 100px;
  position: relative;
  background-color: Rgb(244, 244, 244);
}

.maindiv__card__form {

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.maindiv__form__title {
  color: rgb(110, 185, 55)
}

.maindiv__Card__form__div {

  margin: 10px;
}

.maindiv__Card__form__caption {
  appearance: none;
  border: none;
  outline: none;
  border-bottom: .2em solid rgb(110, 185, 55);
  background-color: rgba(#084cdf, .2);
  border-radius: .2em .2em 0 0;
  padding: .4em;
  color: rgb(110, 185, 55);
  color: #3B3B3B;
  text-align: center;
  font-weight: bold;
}



.maindiv__card__form__file {
  display: flex;
  align-items: center;
  border-radius: 10px;

}

.custom-upload-btn {
  /* background-color: #3498db; */
  background-color: #3B3B3B;
  /* background-color: #444444; */
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.copy__btn {
  background-color: #3B3B3B !important;
  border: 0px !important;
}

#video-upload {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

#file-name {
  margin-left: 10px;
}


.maindiv__card__form__btn {
  appearance: none;
  border: .2em solid rgb(110, 185, 55);
  background: rgb(110, 185, 55);
  padding: .85em 1.5em;
  color: rgb(110, 185, 55);
  border-radius: 2em;
  transition: 1s;
  color: #fff;
}

.maindiv__card__form__btn:hover {

  color: #fff;
}


.data__div {
  width: 100vw;
  display: flex;
  justify-content: center;
  -webkit-animation: slideIn 2s forwards;
  animation: slideIn 2s forwards;

}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

.ul {
  width: 40vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 30vh;
  background-color: Rgb(244, 244, 244);
  border-radius: 20px 100px;
}

.lidiv {
  display: flex;
  align-items: center;
}

.generatelinkbtn {
  appearance: none;
  background-color: transparent;
  border: 2px solid #0060fb;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100px;
  will-change: transform;
}

.generatelinkbtn:disabled {
  pointer-events: none;
}

.generatelinkbtn:hover {
  color: #fff;
  background-color: #0060fb;
  box-shadow: rgb(3, 78, 228) 0 8px 15px;
  transform: translateY(-2px);
}



.generatelinkbtn:active {
  box-shadow: none;
  transform: translateY(0);
}

.btn__two {
  background-color: rgb(103, 172, 85) !important;
  border: 0px !important
}

@media (max-width: 550px) {



  .maindiv__card {
    width: 90vw;
    height: 50vh;

  }



  input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #084cdf;
    padding: 10px 10px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    width: 30vw;
  }

  .maindiv__card__form__file {

    border-radius: 10px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }



  .ul {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .quotediv__text {
    font-size: 20px;
    font-family: Montserrat, sans-serif;
    color: rgb(110, 185, 55);
  }

  .header__title {
    width: 50vw;

  }
}