.emailConfirmation {
    width: 35vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background-color: white;
    color: black;
    margin-right: 30px;

}

@media (max-width:550px) {
    .emailConfirmation {
        width: 80vw;
        height: 40vh;
        font-size: small;
    }
}