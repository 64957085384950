.login__div {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url("../images/cassLogo.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-around;
    /* gap: 15rem; */
    align-items: center;

}

.login__img__left {
    width: 50vw;
    height: 100vh;
    font-size: 35px;
    text-align: center;
    margin-top: 100px;

}

.login__img__right {
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width:500px) {
    .login__div {
        flex-direction: column;
    }

    .login__img__left {
        width: 100vw;
        height: 50vh;
        font-size: 20px;
        text-align: center;
        margin-top: -50px;
    }

    .login__img__right {
        width: 100vw;
        height: 50vh;
        align-self: center;
        display: flex;
        justify-content: center;
    }
}