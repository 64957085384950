.changepassword {
    width: 35vw;
    height: 40vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin-right: 30px;

}

.changepassword__header {
    text-align: center;
    color: rgb(9, 93, 211);
    margin-top: 10px;

}

.changepassword__paper {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width:550px) {
    .changepassword {
        width: 80vw;
        height: 43vh;
        font-size: small;
    }
}