.deleted__page {

    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url("../images/cassLogo.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;


}

.deleted__page__h3 {
    margin-bottom: 70px;
}

/* 
@media (max-width:"600px") {
    .deleted__page {

        display: flex;
        justify-content: center;
        align-items: center;
    }
} */