.announcment {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

}

.announcment__span1 {
    margin-left: 4px;
    animation: effect1 10s linear infinite;
}

.announcment__span2 {
    animation: effect2 10s linear infinite;
}

.announcment__span3 {
    animation: effect3 10s linear infinite;
}

@media (max-width:500px) {
    .announcment {
        flex-direction: column;
        height: 70px;
        padding: 10px;
        /* justify-items: center; */
        text-align: center;
    }
}

@keyframes effect1 {
    0% {
        color: rgb(110, 185, 55);

    }

    100% {
        color: rgb(20, 122, 255);
        /* color: linear-gradient(#3CE7D7, #000FFF); */

    }
}

@keyframes effect2 {
    0% {
        color: rgb(6, 104, 233);
        /* color: linear-gradient(#008000, #00FF00); */
    }

    100% {
        color: rgb(110, 185, 55);
        /* color: linear-gradient(#3CE7D7, #000FFF); */

    }
}

@keyframes effect3 {
    0% {
        color: rgb(93, 223, 0);
        /* color: linear-gradient(#008000, #00FF00); */
    }

    100% {
        color: blue;
        /* color: linear-gradient(#3CE7D7, #000FFF); */

    }
}