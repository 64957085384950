.login__form {
    background-color: rgb(255, 255, 255);

    height: 100%;
    border-radius: 5px;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.login__form__div {
    margin-top: 3rem;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.loginform__forget {
    text-align: center;
    color: #084cdf;
    display: flex;
    justify-content: center;

}

.loginform__p {
    width: 50%;
    cursor: pointer;

}


@media (max-width:550) {
    .loginForm__div {
        width: 60vw
    }
}