.loader {
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

}



.loader__div {
    width: 50vw;
    height: 20vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}


#spin {
    color: rgb(110, 185, 55);
    ;
}

#spin:after {
    content: "";
    animation: spin 20s linear infinite;
}

@keyframes spin {
    0% {
        content: "Your video is being uploaded"
    }

    50% {
        content: "Please hold on";
    }

    100% {
        content: "We are uploading your video";
    }
}