.redirect {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url("../../images/cassLogo.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;

}

.redirect__div {
    width: 50vw;
    height: 50vw;
    text-align: center;
    margin-top: 20px;
}

.redirect__a {
    text-decoration: underline;
    cursor: pointer;
}