.footer {
    margin-top: 100px;
    width: 100vw;
    height: 30vh;
    background-color: Rgb(244, 244, 244);
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Montserrat, sans-serif;
}

.footer__second {

    display: flex;
    width: 30vw;
    justify-content: space-around;
}

ul>li {

    color: #444444;
}

ul>li:hover {
    cursor: pointer;
    text-decoration-line: underline;
    color: #444444;
}



@media (max-width: 550px) {
    .footer {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 60vh;
    }

    .footer__first {
        margin-left: 42px;
    }

    .footer__second {
        display: flex;
        width: 100vw;
        justify-content: space-around;
    }
}