.background {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-image: url("../../../images/cassLogo.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

@media (max-width:"600px") {
    .background {

        display: flex;
        justify-content: center;
        align-items: center;
    }
}