.generateLink {

    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;


}

.link__div {
    width: 60vw;
    height: 40vh;
    border-radius: 100px;
    margin-top: 40px;
    position: relative;
    background-color: Rgb(244, 244, 244);
}

@media (max-width: 550px) {
    .link__div {
        width: 80vw;
        height: 40vh;
        border-radius: 100px;
        margin-top: 40px;
        position: relative;
        background-color: Rgb(244, 244, 244);
    }
}