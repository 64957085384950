.forgotpassword__div {
    width: 35vw;
    height: 40vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-right: 30px;

}

.forgotpassword__inputgroup {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.forgotpassword__label {
    color: black;
}

.forgotpassword__input {
    color: black;
}

.forgotpassword__btn {
    border: .2em solid rgb(110, 185, 55);
    background: rgb(110, 185, 55);
    padding: .85em 1.5em;
    color: rgb(110, 185, 55);
    border-radius: 2em;
    transition: 1s;
    color: #fff;
    width: 150px;
    cursor: pointer;
}

.forgotpassword__btn:disabled {
    background-color: rgb(153, 212, 110);
    border: .2em solid rgb(153, 212, 110);
    cursor: default;
}

@media (max-width:550px) {
    .forgotpassword__div {
        width: 80vw;
        height: 40vh;

    }
}